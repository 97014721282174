import { FC } from "react";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import HelpText from "@/components/HelpText";
import IconWarning from "@/assets/imgs/svg/icon-warning.svg";
import OrderNoteList from "@/components/OrderNoteList";
import useFromPath from "@/hooks/useFromPath";
import Link from "next/link";

type DialogJoinTeamProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const DialogJoinTeam: FC<DialogJoinTeamProps> = ({ isOpen, onClose }) => {
  const { generateLinkWithCurrentPath } = useFromPath();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconWarning width={56} height={56} />}
      title="チームへの加入が必要です"
      btns={
        <Button size="small" asChild>
          <Link href={generateLinkWithCurrentPath("/teams/new")}>
            チーム作成へ
          </Link>
        </Button>
      }
      closeBtnColor="neutral"
    >
      <div className="grid gap-24">
        <div className="grid gap-16">
          <p>
            プロジェクトを投稿するにはチームへの参加が必要です。新しいチームを作成するか、既存のチームに参加してください。
          </p>
          <HelpText
            label="チームについて"
            renderDialog={(isOpen, close) => (
              <Dialog title="チームについて" isOpen={isOpen} onClose={close}>
                <OrderNoteList
                  items={[
                    {
                      label: "チームとは",
                      notes: [
                        "本サービスでの「〇〇設計事務所」「〇〇デザインオフィス」などの設計士の１つのまとまりです。",
                      ],
                    },
                    {
                      label: "チームでできること",
                      notes: ["プロジェクト投稿", "メンバー管理・招待"],
                    },
                    {
                      label: "チームに参加するには",
                      notes: [
                        "既に作成されているチームに参加するには、チームオーナーからの招待を受けるか、参加申請をする必要があります。申請はチームのページから行うことができます。",
                      ],
                    },
                  ]}
                />
              </Dialog>
            )}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default DialogJoinTeam;
